// 初始化
var mdInit = require('../../modules/md.init');
// 弹出框
var mdDialog = require('../../modules/md.dialog');

// 姓名
function validateName(value) {
    var reg = /^[A-Za-z\u4e00-\u9fa5]{1,20}$/gi;

    if (!value) {
        return '姓名不能为空';
    } else if (!reg.test(value)) {
        return '姓名仅支持不超过20位的中文和字母';
    }

    return '';
}

// 邮箱
function validateEmail(value) {
    var reg = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (!value) {
        return '邮箱不能为空';
    } else if (!reg.test(value)) {
        return '邮箱格式错误! 请填写正确邮箱。';
    }

    return '';
}

// 说明
function validateRemarks(value) {
    var reg = /^[0-9a-zA-z\u4e00-\u9fa5]{0,300}$/;

    if (value && !reg.test(value)) {
        return '说明长度不超过300个字符，仅支持中文、数字及字母';
    }

    return '';
}

// 所有操作都在页面初始化方法中进行
mdInit.init(function (config) {
    var thatConfig = config;
    var $header = $('#header');
    var $home = $('#home');
    var $productIndustryBtn = $('#productIndustryBtn');
    var $productIndustrySwipeBox = $('#productIndustrySwipeBox');
    var swipeBoxLeft = 0;

    $home.height(thatConfig.winHeight);

    // 页内锚点跳转
    $header.on('click', '.nav a', function() {
        var tmpId = $(this).attr('href');

        $('html,body').animate({
            scrollTop: $(tmpId).offset().top,
        });

        return false;
    });

    // 跳转联系我们
    $('.js-cantact').on('click', function() {
        $('html,body').animate({
            scrollTop: $('#contact').offset().top,
        });

        return false;
    });


    $productIndustryBtn.on('click', 'a', function() {
        // console.log($(this).data('index'));
        var tmpType = $(this).data('type');

        swipeBoxLeft = tmpType === 'next' ? swipeBoxLeft - 380 : swipeBoxLeft + 380;
        swipeBoxLeft = swipeBoxLeft < -1140 ? -1140 : swipeBoxLeft;
        swipeBoxLeft = swipeBoxLeft >= 0 ? 0 : swipeBoxLeft;

        if (swipeBoxLeft === 0 || swipeBoxLeft === -1140) {
            $(this).addClass('disabled').siblings().removeClass('disabled');
        } else {
            $(this).removeClass('disabled');
        }

        $productIndustrySwipeBox.css({
            left: swipeBoxLeft
        });
    });

    // 联系信息提交
    var $contactForm = $('#contactForm');
    var formSending = false;
    $contactForm.on('click', '.form-title', function() {
        mdDialog.toast({
            content: 'thomwang22',
            type:'error'
        });
    });
    $contactForm.on('click', '.js-submit', function() {
        var tmpForm = $contactForm.serializeArray();
        var tmpParmas = {};
        var tmpMsg = [];
        var tmpTips = '';

        if (formSending) {
            return false;
        }

        for (let i = 0; i < tmpForm.length; i++) {
            tmpTips = '';

            tmpParmas[tmpForm[i].name] = tmpForm[i].value;

            // 姓名
            if (tmpForm[i].name === 'name') {
                tmpTips = validateName(tmpForm[i].value);

                tmpTips && tmpMsg.push(tmpTips);
            }

            // 邮箱
            if (tmpForm[i].name === 'email') {
                tmpTips = validateEmail(tmpForm[i].value);

                tmpTips && tmpMsg.push(tmpTips);
            }

            // 说明
            if (tmpForm[i].name === 'remark') {
                tmpTips = validateRemarks(tmpForm[i].value);

                tmpTips && tmpMsg.push(tmpTips);
            }

            $contactForm.find('.form-item__' + tmpForm[i].name + ' .form-item__error').text(tmpTips);
        }

        console.log(tmpParmas);

        if (tmpMsg.length) {
            return false;
        }

        formSending = true;

        $.ajax({
            url: 'http://capi.cingki.com/api/adminService/user/insertCustomerInformation',
            type: 'POST',
            data: tmpParmas,
            success: function(result) {
                formSending = false;

                mdDialog.toast({
                    content: '提交成功'
                });

                $contactForm[0].reset();
            },
            error: function() {
                formSending = false;
                
                mdDialog.toast({
                    content: '提交失败'
                });
            }
        });

        // mdDialog.toast({
        //     content: tmpMsg.join(';')
        // });
    });
});